import React, { useEffect } from "react";
import { DataTable } from "components/controls/DataTable";
import constants from "utils/constants";
import { useSearchState } from "./useSearchState";
import { HeaderGroup } from "components/controls/DataTable/HeaderGroup";
import { ColumnType } from "components/controls/DataTable/types";
import useStyle from "./useStyle";
import { useConfigureAutomationFilterContext } from "../Context";
import { isEmptyObject } from "utils/miscUtils";
import { useColumns } from "./useColumns";
import { ConfigureAutomationNewOverride } from "../NewOverride";
import { Tooltip } from "primereact/tooltip";
import { Actions } from "./Actions";
import ActionsProvider from "./Actions/Context/Provider";

export const ConfigureAutomationTable = ({ overrideRef }: { overrideRef: React.MutableRefObject<any> }) => {
	const classes = useStyle();
	const { onPage, onSort, searchState } = useSearchState();
	const { columns, data, isLoading } = useColumns();
	const { newRow, editedRowLevel, filters } = useConfigureAutomationFilterContext();

	useEffect(() => {
		onPage({ first: 0, rows: searchState.rows, page: 1 });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	if (isLoading)
		return <div role="presentation" className={["skeleton", "p-card", classes.fallbackContent].join(" ")} />;

	let [...restColumns] = columns ?? [];

	const frozen1 = restColumns.shift() as ColumnType;
	const frozen2 = restColumns.pop() as ColumnType;

	const frozenColumns = HeaderGroup({ columns: [frozen1, frozen2] });
	const headerColumnGroup = HeaderGroup({ columns: restColumns });

	const rows = !isEmptyObject(newRow) && data ? [newRow, ...data] : data;

	return (
		<div id="overlay-checkbox">
			<Tooltip target=".with-tooltip" position="top" />
			<ActionsProvider>
				<DataTable
					columns={columns ?? []}
					headerColumnGroup={headerColumnGroup}
					frozenHeaderColumnGroup={frozenColumns}
					onPage={onPage}
					onSort={onSort}
					scrollable
					frozenWidth="100%"
					alwaysShowPaginator
					paginator={!editedRowLevel}
					className={classes.table}
					searchState={searchState}
					totalRecords={rows?.length}
					loading={isLoading}
					tableName={constants.tableContent.exclusionsTitle}
					paginatorLeft={<ConfigureAutomationNewOverride overrideRef={overrideRef} onPage={onPage} />}
					emptyMessage={constants.tableContent.configurableParametersEmpty}
					data={rows?.slice(searchState.skip, searchState.skip + searchState.take) ?? []}
				/>
				<Actions />
			</ActionsProvider>
		</div>
	);
};
