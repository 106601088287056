import React, { useState } from "react";
import MessagesArea from "components/controls/MessageArea";
import { ApiException } from "api";
import { MessageSeverity } from "utils/messageUtils";

export interface MessageAreaState {
	messageArea: any;
}

interface GenericForm {
	isValid: boolean;
	formValidationErrors: string[];
}

const setMessageAreaBasedOnForm = function (
	setMessageAreaStateFunc: React.Dispatch<
		React.SetStateAction<MessageAreaState>
	>,
	form: any | undefined
) {
	const genericForm = form as GenericForm;
	if (form && form.hasOwnProperty("isValid") && !genericForm.isValid) {
		let messageArea = MessagesArea.getGenericValidationMessage();
		if (
			form.formValidationErrors != null &&
			form.formValidationErrors.length > 0
		) {
			const errors = genericForm.formValidationErrors.join("<br />");
			messageArea = MessagesArea.getGenericValidationMessage(errors);
		}

		setMessageAreaStateFunc({
			messageArea: messageArea
		});
	} else if (form) {
		setMessageAreaStateFunc({
			messageArea: MessagesArea.getGenericSaveSuccessMessageArea()
		});
	} else {
		setMessageAreaStateFunc({
			messageArea: MessagesArea.getGenericErrorMessage()
		});
	}
};

const setMessageAreaBasedOnApiException = function (
	setMessageAreaStateFunc: React.Dispatch<
		React.SetStateAction<MessageAreaState>
	>,
	error: ApiException
) {
	setMessageAreaStateFunc({
		messageArea: MessagesArea.getGenericErrorMessage()
	});
};

const setMessageAreaBasedOnCustomMessage = function (
	setMessageAreaStateFunc: React.Dispatch<
		React.SetStateAction<MessageAreaState>
	>,
	customTitle: string,
	customMessage: string,
	severityLevel: MessageSeverity,
	autoHideMessage: boolean
) {
	setMessageAreaStateFunc({
		messageArea: MessagesArea.getCustomMessage(customTitle, customMessage, severityLevel, autoHideMessage)
	});
};

const useMessageArea = function (): [
	MessageAreaState,
	(form: any) => void,
	(error: ApiException) => void,
	(customTitle: string, customMessage: string, severityLevel: MessageSeverity, autoHideMessage?: boolean) => void,
	React.Dispatch<React.SetStateAction<MessageAreaState>>
] {
	const [messageAreaState, setMessageArea] = useState<MessageAreaState>({
		messageArea: <div />
	});

	const setMessageBasedOnForm = function (form: any) {
		setMessageAreaBasedOnForm(setMessageArea, form);
	};

	const setMessageBasedOnApiException = function (error: ApiException) {
		setMessageAreaBasedOnApiException(setMessageArea, error);
	};

	const setMessageBasedOnCustomMessage = function (customTitle: string,
		customMessage: string,
		severityLevel: MessageSeverity,
		autoHideMessage: boolean = true) {
		setMessageAreaBasedOnCustomMessage(setMessageArea, customTitle, customMessage, severityLevel, autoHideMessage);
	};

	return [
		messageAreaState,
		setMessageBasedOnForm,
		setMessageBasedOnApiException,
		setMessageBasedOnCustomMessage,
		setMessageArea,
	];
};

export default useMessageArea;
