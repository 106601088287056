import React, { useState } from "react";
import useStyle from "./useStyle";
import format from "date-fns/format";
import Page from "components/layouts/Page";
import Menu from "../Menu";
import usePricingPlatformStore from "components/store/usePricingPlatformStore";
import useFirstLoadEffect from "utils/useMountEffect";
import Dropdown from "components/controls/Dropdown";
import { DropdownOption, PricingAdjustmentSummaryReportRequest } from "api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import MultiSelect from "components/controls/MultiSelect";
import constants from "utils/constants";
import { formatDecimalNumber, formatPercentage } from "utils/miscUtils";
import { Calendar } from "primereact/calendar";

const PricingAdjustmentSummaryReportPage: React.FC = function () {
	const classes = useStyle();
	const [appState, appActions] = usePricingPlatformStore();
	const pageState = {
		filterOptions: appState.pricingAdjustmentSummaryReportPageState.filterOptions,
		pricingAdjustmentSummaryReports: appState.pricingAdjustmentSummaryReportPageState.pricingAdjustmentSummaryReports,
		isLoading: appState.pricingAdjustmentSummaryReportPageState.isLoading,
		...appState
	};
	const actions = {
		...appActions.pricingAdjustmentSummaryReportPage,
		...appActions
	};

	//does this not need to use the store because we defined directly here?
	interface LocalState {
		currentFilters: PricingAdjustmentSummaryReportRequest;
		appliedFilters: PricingAdjustmentSummaryReportRequest;
	}

	const [localState, setLocalState] = useState<LocalState>({
		currentFilters: {},
		appliedFilters: {}
	});

	useFirstLoadEffect(() => {
		actions.getForm(localState.currentFilters);
	});

	function onSelectDivision(division: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.division = division;
		curSelections.property = undefined;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
		actions.updateFilterOptions(curSelections);
	}

	function onSelectProperties(properties: DropdownOption[] | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.property = properties;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
	}

	function onSelectStartDate(startDate: Date | Date[]) {
		var curSelections = { ...localState.currentFilters };
		curSelections.startDate = new Date(startDate.toString());
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
	}

	function onSelectEndDate(endDate: Date | Date[]) {
		var curSelections = { ...localState.currentFilters };
		curSelections.endDate = new Date(endDate.toString());
		setLocalState({
			...localState,
			currentFilters: curSelections
		});
	}

	function applySearchDisabled() {
		const divisionNotSelected = !localState.currentFilters.division;

		var propertyNotSelected =
			localState.currentFilters.property === null ||
			localState.currentFilters.property === undefined ||
			localState.currentFilters.property.length === 0;

		var startDateNotSelected =
			localState.currentFilters.startDate === null || localState.currentFilters.startDate === undefined;

		var endDateNotSelected =
			localState.currentFilters.endDate === null || localState.currentFilters.endDate === undefined;

		return (divisionNotSelected && propertyNotSelected) || startDateNotSelected || endDateNotSelected;
	}

	function applySearch() {
		var curSelections = { ...localState.currentFilters };
		setLocalState({
			...localState,
			appliedFilters: curSelections
		});

		actions.searchReportData(curSelections);
	}

	function applySearchHighlight() {
		var a = localState.appliedFilters;
		var b = localState.currentFilters;

		if (JSON.stringify(a) === JSON.stringify(b)) {
			return classes.applyButton;
		}
		if (applySearchDisabled() === true) {
			return classes.applyButton;
		} else {
			return classes.applyButton2;
		}
	}

	function numberTemplate(rowData: any, column: string) {
		return rowData[column].toLocaleString("en-US");
	}

	function applyExport() {
		window.print();
	}

	const displayReport = () => {
		if (
			pageState.pricingAdjustmentSummaryReports !== null &&
			pageState.pricingAdjustmentSummaryReports !== undefined
		) {
			if (pageState.pricingAdjustmentSummaryReports.length === 0) {
				return (
					<div className={classes.empty}>
						<h3>No Pricing Adjustments</h3>
					</div>
				);
			}

			return (
				<div className={classes.reportHeading} style={{ pageBreakAfter: "always" }}>
					<div id="unitTypeHeadingMargin2">
						<DataTable value={pageState.pricingAdjustmentSummaryReports}>
							<Column field="division" header="Division"></Column>
							<Column field="propertyName" header="Property Name"></Column>
							<Column field="unitGroup" header="Unit Group"></Column>
							<Column
								field="adjustedDate"
								headerStyle={{ textAlign: "center" }}
								bodyStyle={{ textAlign: "center" }}
								body={(e: any) => {
									if (e.adjustedDate && e.adjustedDate !== "") {
										return format(new Date(e.adjustedDate), "M/d/yyyy");
									} else {
										return "N/A";
									}
								}}
								header="Adjusted Date"
							></Column>
							<Column
								field="lastChangePercentage"
								header="Last change (%)"
								body={(e: any) => {
									if (e.lastChangePercentage !== undefined && e.lastChangePercentage !== "") {
										return formatPercentage(e.lastChangePercentage);
									} else {
										return "N/A";
									}
								}}
							></Column>
							<Column
								field="lastChange"
								bodyStyle={{ textAlign: "right" }}
								body={(e: any) => numberTemplate(e, "lastChange")}
								header="Last change ($)"
							></Column>
							<Column
								field="leasesNeeded"
								style={{ width: "5%" }}
								bodyStyle={{ textAlign: "center" }}
								headerStyle={{ textAlign: "center" }}
								body={(e: any) => formatDecimalNumber(e.leasesNeeded) || "N/A"}
								header="Leases Needed"
							></Column>
							<Column
								field="ugAtr"
								header="UG ATR"
								body={(e: any) => {
									if (e.ugAtr !== undefined && e.ugAtr !== "") {
										return formatPercentage(e.ugAtr);
									} else {
										return "N/A";
									}
								}}
							></Column>
						</DataTable>
					</div>
				</div>
			);
		} else {
			if (localState.currentFilters.property === null || localState.currentFilters.property === undefined) {
				return <div className={classes.empty}>Please Select A Property Using The Filters Above</div>;
			}
		}
	};

	return (
		<Page menu={<Menu title={constants.menuTitle.pricingAdjustmentSummaryReport} />}>
			<div id="pricingAdjustmentSummaryReportFilters" className={classes.filterHeaders}>
				<div className={classes.filterItem}>
					{"Division:"}
					<Dropdown
						selectedValue={localState.currentFilters.division}
						options={pageState.filterOptions!.divisions}
						onChange={(d) => onSelectDivision(d)}
						disabled={pageState.filterOptions!.divisions === undefined}
						style={{ width: 200 }}
					/>
				</div>
				<div className={classes.filterItem}>
					{"Property:"}
					<MultiSelect
						showTextOfMultipleSelections={true}
						placeholder={"Select properties"}
						selectedValues={localState.currentFilters.property}
						options={pageState.filterOptions!.properties}
						onChange={(d) => onSelectProperties(d)}
						disabled={pageState.filterOptions!.properties === undefined}
						hideClear={false}
						filter
						style={{ width: 350 }}
					></MultiSelect>
				</div>
				<div className={classes.filterItem}>
					<label htmlFor="startDatePicker" title="Start Date">
						Start Date
					</label>
					<div className={classes.calendarOption}>
						<Calendar
							id="startDatePicker"
							style={{ maxHeight: "30px", alignSelf: "center", width: "120px" }}
							value={localState.currentFilters.startDate}
							onChange={(d) => onSelectStartDate(d.value)}
							disabled={pageState.filterOptions!.properties === undefined}
							maxDate={new Date()}
							dateFormat="mm-dd-y"
							placeholder="mm-dd-yy"
							showIcon
						/>
					</div>
				</div>
				<div className={classes.filterItem}>
					<label htmlFor="endDatePicker" title="End Date">
						End Date
					</label>
					<div className={classes.calendarOption}>
						<Calendar
							id="endDatePicker"
							style={{ maxHeight: "30px", alignSelf: "center", width: "120px" }}
							value={localState.currentFilters.endDate}
							onChange={(d) => onSelectEndDate(d.value)}
							disabled={pageState.filterOptions!.properties === undefined}
							maxDate={new Date()}
							dateFormat="mm-dd-y"
							placeholder="mm-dd-yy"
							showIcon
						/>
					</div>
				</div>

				<Button
					label="Apply"
					className={applySearchHighlight()}
					onClick={() => applySearch()}
					disabled={applySearchDisabled()}
				></Button>
				<div className={classes.exportButton}>
					<Button
						label="Export"
						className={classes.applyButton}
						onClick={() => applyExport()}
						disabled={applySearchDisabled()}
					></Button>
				</div>
			</div>
			<div id="contentContainer" className={classes.contentContainer}>
				{pageState.isLoading ? <ProgressSpinner /> : <div className={classes.pageHeading}> {displayReport()}</div>}
			</div>
		</Page>
	);
};

export default PricingAdjustmentSummaryReportPage;
