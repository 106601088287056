import React from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from "recharts";
import useStyle from "./useStyle";
import { siteDefaultTheme } from "theme";
import { formatCurrencyNoFractions, formatDateAsMonth, formatDateString } from "utils/miscUtils";
import { MarketPositionMapped } from "components/forms/PricingAutomation/Pages/Home/Glance/MarketPosition/marketPositionMapper";

// Contain the stroke styles for each specific field
interface Props {
	data: MarketPositionMapped[];
}

const lines = [
	{ name: "ESS Lag", key: "essLag", stroke: siteDefaultTheme.palette.purple },
	{ name: "Local Comp Avg.", key: "localCompAvg", stroke: siteDefaultTheme.palette.softRed, strokeDasharray: "6 6" },
	{ name: "Submarket Max", key: "subMarketMax", stroke: siteDefaultTheme.palette.primaryBlue, strokeDasharray: "2 2" },
	{ name: "Submarket Min", key: "subMarketMin", stroke: siteDefaultTheme.palette.primary, strokeDasharray: "2 2" },
	{ name: "Ess Gross Asking Rent", key: "essGrossAskingRent", stroke: siteDefaultTheme.palette.blue },
	{ name: "Submarket Avg", key: "subMarketAvg", stroke: siteDefaultTheme.palette.darkGray, strokeDasharray: "6 6" }
];

export const MarketPositionChart = ({ data }: Props) => {
	const classes = useStyle();
	return (
		<ResponsiveContainer width="100%" height={260} className={classes.chart}>
			<LineChart data={data}>
				<CartesianGrid strokeDasharray="3 3" vertical={false} />
				<Legend
					wrapperStyle={{ top: "-16px", right: "0px" }}
					verticalAlign="top"
					align="right"
					iconType="plainline"
					iconSize={20}
					width={400}
				/>
				<XAxis
					dataKey="date"
					tickMargin={5}
					height={15}
					tickLine={false}
					allowDuplicatedCategory={false}
					tickFormatter={formatDateAsMonth}
				/>
				<YAxis
					domain={["dataMin", "dataMax"]}
					tickFormatter={formatCurrencyNoFractions}
					tickLine={false}
					axisLine={false}
					width={45}
				/>
				<Tooltip
					contentStyle={{ fontWeight: "bold" }}
					formatter={formatCurrencyNoFractions}
					labelFormatter={formatDateString}
				/>
				{lines.map((line) => (
					<Line
						dot={false}
						isAnimationActive={false}
						name={line.name}
						key={line.key}
						dataKey={line.key}
						stroke={line.stroke}
						strokeDasharray={line.strokeDasharray}
						strokeWidth={1.5}
					/>
				))}
				{data && data.some((x) => x.recommendedPrice) ? (
					<Line
						dot={false}
						isAnimationActive={false}
						name={"Recommended Price"}
						key={"recommendedPrice"}
						dataKey={"recommendedPrice"}
						stroke={siteDefaultTheme.palette.black}
						strokeWidth={1.5}
					/>
				) : (
					<></>
				)}
			</LineChart>
		</ResponsiveContainer>
	);
};
