import React, { useState, useEffect } from "react";
import Page from "components/layouts/Page";
import UnitGroupsAdminMenu from "../UnitGroupsAdminMenu";
import RequirePermission from "components/forms/RequirePermission";
import Spacer from "components/controls/Spacer";
import VisibilityToggle from "components/controls/VisibilityToggle";
import usePricingPlatformStore from "components/store/usePricingPlatformStore";
import constants from "utils/constants";
import useMessageArea from "components/useMessageArea";
import useFirstLoadEffect from "utils/useMountEffect";

import "./_index-page.scss";
import { ProgressSpinner } from "primereact/progressspinner";
import Dropdown, { DropdownOption } from "components/controls/Dropdown";
import { DataTable } from "primereact/datatable";
import Spinner from "components/controls/Spinner";
import { Column } from "primereact/column";
import PrimaryButton from "components/controls/PrimaryButton";
import { MessageSeverity, MessageSeverityLevel } from "utils/messageUtils";
import { UnitGroupAdminPageProps } from "components/store/UnitGroupAdminPage/unitGroupAdminPageInterfaces";
import { UnitGroupAdminDropdownSelections, UnitGroupAdminSummary, UnitType } from "api";
import routes from "components/routes";
import { useHistory } from "react-router-dom";
import { Card } from "primereact/card";

const UnitGroupAdminPage: React.FC<UnitGroupAdminPageProps> = function (props) {
	const [appState, appActions] = usePricingPlatformStore();
	const pageState = { ...appState.unitGroupAdminPageState };
	const actions = { ...appActions.unitGroupAdminPage };
	const [, , , setMessageBasedOnCustomMessage] = useMessageArea();
	const history = useHistory();

	interface defaultUnitGroupDropdowns {
		currentFilters: UnitGroupAdminDropdownSelections;
	}

	const [defaultUGDropdowns] = useState<defaultUnitGroupDropdowns>({
		currentFilters: {}
	});

	const [localState, setLocalState] = useState({
		unitGroups: appState.unitGroupAdminPageState.pagedUnitGroups.unitGroups,
		totalCount: appState.unitGroupAdminPageState.pagedUnitGroups.totalCount,
		filterOptions: appState.unitGroupAdminPageState.unitGroupFilterOptions,
		currentFilters: defaultUGDropdowns.currentFilters,
		showCreateDialog: false
	});

	const rowsPerPageOptions: number[] = [10, 20, 50];

	interface SearchState {
		skip: number;
		take: number;
		rows: number;
		page: number;
		totalRecords: number;
	}

	const [searchState, setSearchState] = useState<SearchState>({
		skip: 0,
		take: 10,
		rows: rowsPerPageOptions[0],
		page: 0,
		totalRecords: 0
	});

	function unitTypesTemplate(x: UnitGroupAdminSummary) {
		var list = x.unitTypes ? [...x.unitTypes] : [];

		if (list) {
			list.sort();
		}

		return (
			<span>
				{list
					.map((y: UnitType) => {
						return y.id;
					})
					.join(", ")}
			</span>
		);
	}

	useFirstLoadEffect(() => {
		try {
			actions.updateUnitGroupMappingFilterOptionsAndSearch(
				localState.currentFilters,
				searchState.skip,
				searchState.take,
				props.match.params
			);
		} catch (error) {
			setMessageBasedOnCustomMessage(
				constants.MessageAreaMessages.ApiDownTitle,
				constants.MessageAreaMessages.ApiDownMessage,
				MessageSeverityLevel.Warning as MessageSeverity,
				false
			);
		}
	});

	useEffect(() => {
		localState.unitGroups = pageState.pagedUnitGroups.unitGroups;
		localState.totalCount = pageState.pagedUnitGroups.totalCount;
	}, [
		localState.unitGroups,
		localState.totalCount,
		pageState.pagedUnitGroups.unitGroups,
		pageState.pagedUnitGroups.totalCount
	]);

	function onSelectDivision(division: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedDivision = division;
		curSelections.selectedProperty = undefined;

		setSearchState({
			...searchState,
			skip: 0,
			page: 0
		});

		setLocalState({
			...localState,
			currentFilters: curSelections
		});

		if (curSelections.selectedDivision === undefined) {
			actions.clearUnitGroupMapping();
			setLocalState({
				...localState,
				unitGroups: [],
				totalCount: 0,
				currentFilters: curSelections
			});
		}

		actions.updateUnitGroupMappingFilterOptionsAndSearch(curSelections, 0, searchState.take);
		if (curSelections.selectedDivision !== undefined) {
			actions.searchProperty(curSelections, 0, searchState.take);
		}
	}

	function onSelectProperty(property: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedProperty = property;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});

		setSearchState({
			...searchState,
			skip: 0,
			page: 0
		});

		if (curSelections.selectedProperty === undefined && curSelections.selectedDivision === undefined) {
			setLocalState({
				...localState,
				unitGroups: [],
				currentFilters: curSelections,
				totalCount: 0
			});
		}

		actions.searchProperty(curSelections, 0, searchState.take);
	}

	function onEdit(e: { originalEvent: Event; data: UnitGroupAdminSummary; index: number }) {
		var ugId = e.data.unitGroupId;
		var divId = localState.currentFilters.selectedDivision
			? localState.currentFilters.selectedDivision.key
			: undefined;
		var divName = localState.currentFilters.selectedDivision
			? encodeURIComponent(localState.currentFilters.selectedDivision.displayValue!)
			: undefined;
		var propId = e.data.propertyId;
		var propName = e.data.propertyName;

		history.push(routes.unitGroupAdmin.updateUnitGroup(divId, divName, propId, propName, ugId));
	}

	function onPage(e: any) {
		var curSelections = { ...localState.currentFilters };
		actions.searchProperty(curSelections, e.first, e.rows);

		setSearchState({
			...searchState,
			skip: e.first,
			take: e.rows,
			page: e.page
		});
	}

	function onCreate() {
		var divId = localState.currentFilters.selectedDivision
			? localState.currentFilters.selectedDivision.key
			: undefined;
		var divName = localState.currentFilters.selectedDivision
			? encodeURIComponent(localState.currentFilters.selectedDivision.displayValue!)
			: undefined;
		var propId = localState.currentFilters.selectedProperty
			? localState.currentFilters.selectedProperty.key
			: undefined;
		var propName = localState.currentFilters.selectedProperty!.displayValue;
		history.push(routes.unitGroupAdmin.createUnitGroup(divId, divName, propId, propName));
	}

	const dataTable = () => {
		if (pageState.pagedUnitGroups === undefined) {
			return <Spinner isVisible={true} />;
		} else {
			return (
				<Card>
					<DataTable
						id="unitGroup-list"
						value={localState.unitGroups}
						paginator={true}
						alwaysShowPaginator={true}
						pageLinkSize={5}
						className="unitgroup-list p-datatable-striped"
						tableClassName="unitgroup-list-table"
						lazy={true}
						loading={pageState.tableLoading}
						totalRecords={localState.totalCount}
						onPage={onPage}
						first={searchState.skip}
						rows={searchState.take}
						rowsPerPageOptions={rowsPerPageOptions}
						onRowClick={(e: { originalEvent: Event; data: UnitGroupAdminSummary; index: number }) => {
							onEdit(e);
						}}
						dataKey="id"
						emptyMessage="No unit groups found. You either need to select a Property, or you do not have permission to view any unit groups"
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords} unit groups"
					>
						<Column
							field="propertyName"
							header="Property"
							bodyStyle={{ width: "200px" }}
							headerStyle={{ width: "200px" }}
						></Column>
						<Column
							field="unitGroupName"
							header="Unit Group"
							bodyStyle={{ width: "125px" }}
							headerStyle={{ width: "125px" }}
						></Column>
						<Column
							field="description"
							header="Description"
							bodyStyle={{ marginLeft: "10px", marginRight: "10px", width: "145px", textAlign: "center" }}
							headerStyle={{ marginLeft: "10px", marginRight: "10px", width: "145px", textAlign: "center" }}
						></Column>
						<Column
							field="classification"
							header="Classification"
							bodyStyle={{ marginLeft: "10px", marginRight: "10px", width: "145px", textAlign: "center" }}
							headerStyle={{ marginLeft: "10px", marginRight: "10px", width: "145px", textAlign: "center" }}
						></Column>
						<Column
							header="Unit Types"
							body={unitTypesTemplate}
							bodyStyle={{ width: "25%", textAlign: "left" }}
							headerStyle={{ textAlign: "left" }}
						></Column>
						<Column
							field="createdTime"
							header="Created"
							bodyStyle={{ width: "8%" }}
							headerStyle={{ width: "8%" }}
						></Column>
						<Column
							field="lastUpdatedTime"
							header="Last Updated"
							bodyStyle={{ width: "10%" }}
							headerStyle={{ width: "10%" }}
						></Column>
						<Column
							field="lastUpdatedBy"
							header="Last Updated By"
							bodyStyle={{ width: "14%" }}
							headerStyle={{ width: "14%" }}
						></Column>
					</DataTable>
				</Card>
			);
		}
	};

	const navbarActionArea = (
		<RequirePermission permissions={[constants.permissions.canViewAllAdminUnitGroups]}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<Spacer orientation="h" size="xl" />
				<VisibilityToggle isVisible={!pageState.isLoading}></VisibilityToggle>
			</div>
		</RequirePermission>
	);

	return (
		<Page menu={<UnitGroupsAdminMenu actionArea={navbarActionArea} />} className="unit-group-admin-page">
			<div id="unitGroupAdminFilters" className="filter-headers-1">
				<div className="filter-item">
					<span className="filter-title">Division</span>
					<div className="filter-controls">
						<Dropdown
							placeholder={"Select division"}
							selectedValue={localState.currentFilters.selectedDivision}
							options={pageState.unitGroupFilterOptions!.divisions}
							onChange={(d) => onSelectDivision(d)}
							disabled={pageState.unitGroupFilterOptions!.divisions === undefined}
							style={{ width: 350 }}
						/>
					</div>
				</div>
				<div className="filter-item">
					<span className="filter-title">Property</span>
					<div className="filter-controls">
						<Dropdown
							placeholder={"Select property"}
							selectedValue={localState.currentFilters.selectedProperty}
							options={pageState.unitGroupFilterOptions!.properties}
							onChange={(d) => onSelectProperty(d)}
							disabled={pageState.unitGroupFilterOptions!.properties === undefined}
							filter
							style={{ width: 350 }}
						></Dropdown>
					</div>
				</div>
				<PrimaryButton
					className="createButton"
					disabled={!localState.currentFilters.selectedProperty}
					onClick={onCreate}
					title="CREATE UNIT GROUP MAPPING"
				/>
			</div>
			<div id="contentContainer" className="">
				{pageState.isLoading ? <ProgressSpinner /> : <div className="indexBody">{dataTable()}</div>}
			</div>
		</Page>
	);
};

export default UnitGroupAdminPage;
