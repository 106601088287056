import React, { useState, useEffect } from "react";
import Page from "components/layouts/Page";
import UnitGroupsAdminMenu from "./../UnitGroupsAdminMenu";
import RequirePermission from "./../../RequirePermission";
import Spacer from "components/controls/Spacer";
import VisibilityToggle from "components/controls/VisibilityToggle";
import usePricingPlatformStore from "components/store/usePricingPlatformStore";
import constants from "utils/constants";
import useMessageArea from "components/useMessageArea";
import useFirstLoadEffect from "utils/useMountEffect";

import "./_list-classifications.scss";
import { DataTable } from "primereact/datatable";
import Spinner from "components/controls/Spinner";
import { Column } from "primereact/column";
import PrimaryButton from "components/controls/PrimaryButton";
import { format } from "date-fns";
import { MessageSeverity, MessageSeverityLevel } from "utils/messageUtils";
import { UnitGroupAdminPageProps } from "components/store/UnitGroupAdminPage/unitGroupAdminPageInterfaces";
import routes from "components/routes";
import { useHistory } from "react-router-dom";
import { Card } from "primereact/card";
import { ClassificationForm } from "api";

const ClassificationsAdminPage: React.FC<UnitGroupAdminPageProps> = function (props) {
	const [appState, appActions] = usePricingPlatformStore();
	const pageState = { ...appState.unitGroupAdminPageState };
	const actions = { ...appActions.unitGroupAdminPage };
	const [, , , setMessageBasedOnCustomMessage] = useMessageArea();
	const history = useHistory();

	const [localState] = useState({
		classifications: appState.unitGroupAdminPageState.classifications,
	});	
	interface SearchState {
		skip: number;
		take: number;
		rows: number;
		page: number;
		totalRecords: number;
	}

	const navbarActionArea = (
		<RequirePermission permissions={[constants.permissions.canViewAllAdminUnitGroups]}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<Spacer orientation="h" size="xl" />
				<VisibilityToggle isVisible={!pageState.isLoading}></VisibilityToggle>
			</div>
		</RequirePermission>
	);

	useFirstLoadEffect(() => {
		try {
			actions.getClassifications();
		} catch (error) {
			setMessageBasedOnCustomMessage(
				constants.MessageAreaMessages.ApiDownTitle,
				constants.MessageAreaMessages.ApiDownMessage,
				MessageSeverityLevel.Warning as MessageSeverity,
				false
			);
		}
	});

	useEffect(() => {
		localState.classifications = pageState.classifications;
	}, [localState.classifications, pageState.classifications]);


	function onEdit(e: { originalEvent: Event; data: ClassificationForm; index: number }) {
		var classificationId = e.data.id;
		history.push(routes.unitGroupAdmin.updateClassification(classificationId));		
	}

	function onCreate() {
		history.push(routes.unitGroupAdmin.createClassification);
	}

	function createdDateTemplate(e: ClassificationForm) {
		if (e.createdDate === undefined) {
			return <span>N/A</span>;
		} else {
			return <span>{format(new Date(e.createdDate), "M/d/yyyy")}</span>;
		}
	}

	function updatedDateTemplate(e: ClassificationForm) {
		if (e.lastUpdatedDate === undefined) {
			return <span>N/A</span>;
		} else {
			return <span>{format(new Date(e.lastUpdatedDate), "M/d/yyyy")}</span>;
		}
	}

	const dataTable = () => {
		if (pageState.classifications === undefined) {
			return <Spinner isVisible={true} />;
		} else if (
			!pageState.isLoading &&
			pageState.classifications &&
			pageState.classifications.length === 0
		) {
			return (
				<div className="error-message-styling">
					No classifications found. You either need to adjust the page filters, or you do not have permission to view
					any classifications.
				</div>
			);
		} else {
			return (
				<Card>
					<DataTable
						id="classification-list"
						value={localState.classifications}
						pageLinkSize={5}
						className="classification-list p-datatable-striped"
						tableClassName="classification-list-table"
						lazy={true}
						loading={pageState.tableLoading}
						onRowClick={(e: { originalEvent: Event; data: ClassificationForm; index: number }) => {
							onEdit(e);
						}}
						dataKey="id"
						emptyMessage="No classifications found"
					>
						<Column
							field="name"
							header="Name"
							bodyStyle={{ marginLeft: "10px", marginRight: "10px", width: "30%", textAlign: "left" }}
							headerStyle={{ marginLeft: "10px", marginRight: "10px", width: "30%", textAlign: "left" }}
						></Column>
						<Column
							field="description"
							header="Description"
							bodyStyle={{ marginLeft: "10px", marginRight: "10px", width: "30%", textAlign: "left" }}
							headerStyle={{ marginLeft: "10px", marginRight: "10px", width: "30%", textAlign: "left" }}
						></Column>
						<Column
							field="createdDate"
							header="Created"
							body={createdDateTemplate}
							bodyStyle={{ width: "16%" }}
							headerStyle={{ width: "16%" }}
						></Column>
						<Column
							field="lastUpdatedDate"
							header="Last Updated"
							body={updatedDateTemplate}
							bodyStyle={{ width: "17%" }}
							headerStyle={{ width: "17%" }}
						></Column>
						<Column
							field="lastUpdatedBy"
							header="Last Updated By"
							bodyStyle={{ width: "20%" }}
							headerStyle={{ width: "20%" }}
						></Column>
					</DataTable>
				</Card>
			);
		}
	};

	return (
		<Page menu={<UnitGroupsAdminMenu actionArea={navbarActionArea} />} className="classification-page">
			<div id="unitGroupAdminFilters" className="filter-headers-1">
				<PrimaryButton className="createButton" onClick={onCreate} title="CREATE UNIT GROUP CLASSIFICATION" />
			</div>
			<div id="contentContainer" className="">
				{pageState.isLoading ? <Spinner isVisible={true}/> : <div className="classificationsIndexBody">{dataTable()}</div>}
			</div>
		</Page>
	);
};

export default ClassificationsAdminPage;
