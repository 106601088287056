import { IconButton } from "components/controls/PricingIconButton";
import React from "react";
import { useActionsContext } from "./Context/context";
import { useConfigureAutomationFilterContext } from "../../Context";

interface Props {
	data: any;
	columnKey: string;
}

export const DeleteColumn = ({ data, columnKey }: Props) => {
	const { handleOpen, handleData } = useActionsContext();
	const { editedRowLevel } = useConfigureAutomationFilterContext();
	const disabled = !!editedRowLevel;
	const hide = data["name"] === "Portfolio" || disabled;

	const onOpen = (type: string) => {
		handleOpen(type);
		handleData({ ...data, columnKey });
	};

	return (
		<>
			{!hide ? (
				<IconButton disabled={disabled} type="button" onClick={() => onOpen("delete-column")}>
					<i className="pi pi-times" style={{ fontSize: "10px" }} />
				</IconButton>
			) : null}
		</>
	);
};
