import { KeyValuePairOfStringAndListOfInteger, LeaseTermAdminClient, LeaseTermConfigurationsForm } from "api";
import { useContext } from "react";
import { getApiEndpoint } from "components/useApiEndpoint";
import { IdentityContext } from "components/IdentityProvider";
import { makeRemoteRequest } from "components/useRemoteData";

export const useLeaseTermAdminService = () => {
	const identity = useContext(IdentityContext);

	const getLeaseTermConfigurations = async (
		payload?: string,
		signal?: AbortSignal
	): Promise<LeaseTermConfigurationsForm> => {
		const endpoint = getApiEndpoint(identity, LeaseTermAdminClient, "getLeaseTermConfigurations", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [payload]);
			if (data === undefined) {
				throw new Error();
			}
			return data as LeaseTermConfigurationsForm;
		} catch (err) {
			throw err;
		}
	};

	const checkLeaseTermConfigurations = async (
		payload?: string[],
		signal?: AbortSignal
	): Promise<KeyValuePairOfStringAndListOfInteger[]> => {
		const endpoint = getApiEndpoint(identity, LeaseTermAdminClient, "checkLeaseTermConfigurations", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [payload ?? []]);
			if (data === undefined) {
				throw new Error();
			}
			return data as KeyValuePairOfStringAndListOfInteger[];
		} catch (err) {
			throw err;
		}
	};

	const saveLeaseTermConfigurations = async (
		payload: LeaseTermConfigurationsForm,
		signal?: AbortSignal
	): Promise<LeaseTermConfigurationsForm> => {
		const endpoint = getApiEndpoint(identity, LeaseTermAdminClient, "saveLeaseTermConfigurationDivision", signal);
		try {
			const { data } = await makeRemoteRequest(endpoint, [payload]);
			if (data === undefined) {
				throw new Error();
			}
			return data as LeaseTermConfigurationsForm;
		} catch (err) {
			throw err;
		}
	};

	return {
		getLeaseTermConfigurations,
		saveLeaseTermConfigurations,
		checkLeaseTermConfigurations,
	};
};
