import { z } from "zod";

const percentageValidation = z.union([
	z
		.number({
			invalid_type_error: "This field should be a number"
		})
		.optional()
		.nullable(),
	z.nan()
]);

const numberValidation = z.union([
	z
		.number({
			invalid_type_error: "This field should be a number"
		})
		.optional()
		.nullable(),
	z.nan()
]);

const dateValidation = z
	.date({
		invalid_type_error: "This Field should be a date"
	})
	.optional()
	.nullable();

const booleanValidation = z
	.boolean({
		invalid_type_error: "This Field should be a boolean"
	})
	.optional()
	.nullable();

export const configureAutomationSchema = z
	.object({
		name: z.string(),
		APG_endDate: dateValidation,
		APG_startDate: dateValidation,
		APG_maxValue: percentageValidation,
		"Adjustment Frequency (days)_minValue": numberValidation,
		"Adjustment Magnitude Days_minValue": numberValidation,
		"Adjustment Magnitude Float_minValue": percentageValidation,
		"Length of time in high ATR": numberValidation,
		"UG Concession 2_minValue": numberValidation,
		"UG Concession 1_minValue": numberValidation,
		"UG Atr Target Low_maxValue": percentageValidation,
		"UG Atr Target High_minValue": percentageValidation,
		"UG Atr Target Middle_minValue": percentageValidation,
		"UG Atr Target Middle_maxValue": percentageValidation,
		"UG ATR Goal_maxValue": percentageValidation,
		"UG Lease Velocity Middle_minValue": numberValidation,
		"UG Lease Velocity Middle_maxValue": numberValidation,
		"UG Lease Velocity High_minValue": numberValidation,
		"Resident Retention Goal_minValue": percentageValidation,
		"UG LTA / GTA Target Low_minValue": percentageValidation,
		"UG Lease Velocity Low_maxValue": numberValidation,
		"Property Lead Volume Target Below_maxValue": percentageValidation,
		"Property Net Close Target Below_maxValue": percentageValidation,
		"Stale Units_minValue": numberValidation,
		"Property Lead Volume Target Above_minValue": percentageValidation,
		"UG Manual Exclusion_isExcluded": booleanValidation,
		"UG Manual Exclusion_endDate": dateValidation,
		"UG Manual Exclusion_startDate": dateValidation,
		"Property Net Conversion Target Below_maxValue": percentageValidation,
		"Property Net Conversion Target Above_minValue": percentageValidation,
		"UG LTA / GTA Target High_maxValue": percentageValidation,
		"UG Use LTA GTA_paramFlagValue": booleanValidation,
		"Property Net Close Target Above_minValue": percentageValidation,
		"Manual Exclusions Reminder (Days)_minValue": numberValidation,
		"Length of time in high ATR_minValue": numberValidation,
		"UG Exclusion Unit Count_minValue": numberValidation,
		"UG Retention Percent_maxValue": percentageValidation,
		"UG Healthy Conversion Ratio_maxValue": percentageValidation,
		"UG LTA / GTA Target Middle_minValue": percentageValidation,
		"UG LTA / GTA Target Middle_maxValue": percentageValidation,
		"Std Upper Local Comp_minValue": numberValidation,
		"Std Lower Local Comp_minValue": numberValidation,
		"Std Upper Submarket_minValue": numberValidation,
		"Std Lower Submarket_minValue": numberValidation,
		"Pricing Increment Low Increase_minValue": percentageValidation,
		"Pricing Increment Low Decrease_minValue": percentageValidation,
		"Pricing Increment Moderate Increase_minValue": percentageValidation,
		"Pricing Increment Moderate Decrease_minValue": percentageValidation,
		"Pricing Increment High Increase_minValue": percentageValidation,
		"Pricing Increment High Decrease_minValue": percentageValidation,
		"Pricing Increment VHigh Increase_minValue": percentageValidation,
		"Pricing Increment VHigh Decrease_minValue": percentageValidation
	})
	.refine(
		(data) =>
			!(
				data.name !== "Portfolio" &&
				data["UG Manual Exclusion_isExcluded"] != null &&
				data["UG Manual Exclusion_endDate"] == null
			),
		{
			message: "Must provide an end date.",
			path: ["UG Manual Exclusion_endDate"]
		}
	)
	.refine(
		(data) =>
			!(
				data.name !== "Portfolio" &&
				data["UG Manual Exclusion_isExcluded"] != null &&
				data["UG Manual Exclusion_startDate"] == null
			),
		{
			message: "Must provide a start date.",
			path: ["UG Manual Exclusion_startDate"]
		}
	);

export type configureAutomationFormType = z.infer<typeof configureAutomationSchema>;
