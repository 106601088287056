import routes from "components/routes";
import React from "react";
import constants from "utils/constants";
import useStyle from "./useStyle";
import RequirePermission from "../RequirePermission";
import { Link } from "react-router-dom";
import { UserPermissions } from "api";

type PermissionName = keyof UserPermissions;

interface Props {
	currentPath: string;
}

export const DrawerItems = ({ currentPath }: Props) => {
	const classes = useStyle();
	const headerMenu = [
		{
			active: true,
			title: "HOME",
			icon: "pi pi-home",
			linkTo: routes.pricingAutomation.default,
			permission: [constants.permissions.canViewPropertiesArea]
		},
		{
			active: true,
			title: "PROPERTIES",
			icon: "pi pi-building",
			linkTo: routes.properties(),
			permission: [constants.permissions.canViewPropertiesArea]
		},
		{
			active: true,
			title: "UNITS",
			icon: "pi pi-th-large",
			linkTo: routes.units(),
			permission: [constants.permissions.canViewUnitsArea]
		},
		{
			active: true,
			title: "REPORTS",
			icon: "pi pi-chart-pie",
			linkTo: routes.reports,
			permission: [constants.permissions.canViewReportsArea]
		},
		{
			active: true,
			title: "ADMIN",
			icon: "pi pi-lock",
			linkTo: routes.admin.default,
			permission: [constants.permissions.canViewAdminArea]
		},
		{
			active: true,
			title: "CONFIGURE AUTOMATION",
			icon: "pi pi-cog",
			linkTo: routes.pricingAutomation.configureAutomation,
			permission: [constants.permissions.canViewReportsArea]
		},
		{
			active: constants.featureToggle.viewExceptions,
			title: "EXCEPTIONS",
			icon: "pi pi-file-excel",
			linkTo: routes.exceptions(),
			permission: [constants.permissions.canViewAllExceptions]
		}
	];

	return (
		<nav className={classes.navContainer}>
			{headerMenu.map((item) => {
				return (
					item.active && (
						<RequirePermission
							key={`${item.title}`}
							permissions={item.permission as PermissionName[]}
							showSpinner={false}
						>
							<Link
								to={item.linkTo}
								className={[classes.linkItem, currentPath === item.linkTo ? classes.linkActive : ""].join(" ")}
							>
								<i className={item.icon} style={{ fontSize: "1.8rem" }} />
								{item.title}
							</Link>
						</RequirePermission>
					)
				);
			})}
		</nav>
	);
};
