import { useQuery } from "components/forms/PricingAutomation/hooks/useQuery";

const handleType = (type: string) => {
	if (["BMR", "ADU"].includes(type)) {
		return {
			classificationType: type
		};
	} else if (type === "SMALL UNIT GROUP") {
		return {
			isSmallUnitGroup: true
		};
	} else if (type === "MANUAL EXCLUSION") {
		return {
			isManualExcluded: true
		};
	} else {
		return {};
	}
};

export const useMapFilterKeys = () => {
	const searchParams = useQuery();
	const reason = searchParams.get("reason") ?? "";
	const division = searchParams.get("division") ?? "";
	const county = searchParams.get("county") ?? "";
	const property = searchParams.get("property") ?? "";
	const group = searchParams.get("group") ?? "";
	const manualExclusion = searchParams.get("manualExclusion") ?? "";

	return {
		countyId: county,
		divisionId: division,
		propertyIds: property ? property?.split(",") : [],
		unitGroupMasterName: group,
		manualExclusionFilter: manualExclusion,
		...handleType(reason)
	};
};
