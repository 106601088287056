import Page from "components/layouts/Page";
import React, { useEffect, useState } from "react";
import Menu from "../Menu";
import Dropdown from "components/controls/Dropdown";
import Spinner from "components/controls/Spinner";
import { LeaseTermSelectionForm } from "./LeaseTermSelectionForm";
import { ApiException, LeaseTermAdminClient, LeaseTermConfigurationsForm } from "api";
import { useLeaseTermAdminService } from "components/services/leaseTermAdmin.service";
import { useApiEndpoint } from "components/useApiEndpoint";
import useMessageArea from "components/useMessageArea";
import { useRemoteData, responseHandlers } from "components/useRemoteData";
import PrimaryButton from "components/controls/PrimaryButton";
import UnsavedChangesPrompt from "components/controls/UnsavedChangesPrompt";
import Spacer from "components/controls/Spacer";
import useStyle from "./useStyle";

export const LeaseTermAdminPage = () => {
	const { getLeaseTermConfigurations } = useLeaseTermAdminService();
	const classes = useStyle();

	const [pageState, setPageState] = useState({ isLoading: false, isChanged: false });
	const [formState, setFormState] = useState<LeaseTermConfigurationsForm>({});
	const [messageAreaState, setMessageBasedOnForm, setMessageBasedOnApiException] = useMessageArea();

	const [submitState, submit] = useRemoteData(
		useApiEndpoint(LeaseTermAdminClient, "saveLeaseTermConfigurationDivision"),
		[formState],
		{
			onChange: (form) => {
				setMessageBasedOnForm(form);
				setFormState(form ?? {});
				setPageState({ ...pageState, isLoading: false, isChanged: false });
			},
			onError: (error: ApiException) => {
				responseHandlers.use({
					"400": (ex: ApiException) => {
						const form: LeaseTermConfigurationsForm = ex.result;
						setMessageBasedOnForm(form);
						setFormState(form ?? {});
						setPageState({ ...pageState, isLoading: false, isChanged: true });
					},
					_: (ex) => {
						setPageState({ ...pageState, isLoading: false, isChanged: true });
						setMessageBasedOnApiException(ex);
					}
				})(error);
			}
		}
	);

	const loadData = async (id: string) => {
		try {
			setPageState({ ...pageState, isLoading: true });
			const data = await getLeaseTermConfigurations(id);
			setFormState(data);
		} finally {
			setPageState({ ...pageState, isLoading: false, isChanged: false });
		}
	};

	useEffect(() => {
		const loadData = async () => {
			try {
				setPageState({ ...pageState, isLoading: true });
				const data = await getLeaseTermConfigurations();
				setFormState(data);
			} finally {
				setPageState({ ...pageState, isLoading: false });
			}
		};
		loadData();
	}, []);

	const onSelectDivision = async (d: any) => {
		if (!d) {
			setFormState({ ...formState, selectedDivision: undefined });
			setPageState({ ...pageState, isChanged: false });
			return;
		}
		await loadData(d.key);
		setPageState({ ...pageState, isChanged: false });
	};

	const handleSaveAction = () => {
		setPageState({ ...pageState, isLoading: true });
		submit([formState]);
	};
	const handleFormChange = (form: LeaseTermConfigurationsForm) => {
		setPageState({ ...pageState, isChanged: true });
		setFormState(form);
	};

	return (
		<Page menu={<Menu title="LEASE TERM ADMIN" />} className="unit-group-admin-page">
			{messageAreaState.messageArea}
			<Spinner isVisible={pageState.isLoading}></Spinner>

			<div className="filter-item">
				<div className={classes.filterControls}>
					<Dropdown
						placeholder={"Select division"}
						selectedValue={formState?.selectedDivision}
						options={formState?.divisions ?? []}
						onChange={(d) => onSelectDivision(d)}
						hideClear
						style={{ width: 350 }}
					/>
					<div className={classes.saveControls}>
						<UnsavedChangesPrompt isVisible={pageState.isChanged} />
						<Spacer />
						<PrimaryButton title={"SAVE"} onClick={handleSaveAction} disabled={!pageState.isChanged} />
					</div>
				</div>
			</div>
			{formState ? (
				<LeaseTermSelectionForm
					form={formState}
					onUpdateSelectedDivision={handleFormChange}
					selectedDivision={formState.selectedDivision}
				/>
			) : (
				<></>
			)}
		</Page>
	);
};
