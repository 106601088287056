import React, { useState, useEffect } from "react";
import Page from "components/layouts/Page";
import RequirePermission from "./../../RequirePermission";
import Spacer from "components/controls/Spacer";
import VisibilityToggle from "components/controls/VisibilityToggle";
import usePricingPlatformStore from "components/store/usePricingPlatformStore";
import constants from "utils/constants";
import useMessageArea from "components/useMessageArea";
import useFirstLoadEffect from "utils/useMountEffect";

import "./_create-classification.scss";
import PrimaryButton from "components/controls/PrimaryButton";
import { MessageSeverity, MessageSeverityLevel } from "utils/messageUtils";
import { UnitGroupAdminPageProps } from "components/store/UnitGroupAdminPage/unitGroupAdminPageInterfaces";
import routes from "components/routes";
import { useHistory, useParams } from "react-router-dom";
import { Card } from "primereact/card";
import Dialog from "components/controls/Dialog";
import { Button } from "primereact/button";
import Input from "components/controls/Input";
import Label from "components/controls/Label";
import {EditClassificationMenu, CreateClassificationMenu} from "./ClassificationMenu";
import { ClassificationForm } from "api";
import Spinner from "components/controls/Spinner";
import { isNullOrUndefinedOrEmptyOrWhiteSpace } from "utils/miscUtils";

interface UpdateClassificationParams {
    classificationId: string;
};

const CreateClassificationAdminPage: React.FC<UnitGroupAdminPageProps> = function (props) {
	const [appState, appActions] = usePricingPlatformStore();
	const pageState = {
		currentClassification: appState.unitGroupAdminPageState.updateClassification,
		isLoading: appState.unitGroupAdminPageState.isLoading,
		...appState
	};
	const actions = { ...appActions.unitGroupAdminPage };
	const [, , , setMessageBasedOnCustomMessage] = useMessageArea();
	const history = useHistory();
	const params = useParams<UpdateClassificationParams>();   

    //Track form state
    const [name, setName] = useState<string | undefined | null>(undefined);
	const [description, setDescription] = useState<string | undefined | null>(undefined);
    const [saveError, setSaveError] = useState(false);
	const [classificationLoading, setClassificationLoading] = useState(true);

	useEffect(() => {
		setName(pageState.currentClassification.name);
		setDescription(pageState.currentClassification.description);
	}, [pageState.currentClassification])


	useFirstLoadEffect(() => {
		try {
			if (params.classificationId !== undefined) {
				actions.loadClassification(params.classificationId)
				.then(() => {
					setClassificationLoading(false);
				})
			} else {
				setClassificationLoading(false);
			}
		} catch (error) {
			setMessageBasedOnCustomMessage(
				constants.MessageAreaMessages.ApiDownTitle,
				constants.MessageAreaMessages.ApiDownMessage,
				MessageSeverityLevel.Warning as MessageSeverity,
				false
			);
		}
	});

	const renderFooter = () => {
		return (
			<div>
				<Button label="OK" icon="pi pi-check" onClick={() => setSaveError(false)} />
			</div>
		);
	};

    function onCancel(){
		history.push(routes.unitGroupAdmin.classifications);
	}

	function onSave(){
		if (params.classificationId !== undefined) {
			saveUpdate();
		} else {
			saveNew();
		}
	}

	function saveNew() {
		const form = {name: name, description: description} as ClassificationForm;

		actions.createClassification(form)
			.then(() => {
				history.push(routes.unitGroupAdmin.classifications);
			});
	}

	function saveUpdate() {
		const form = {
			...pageState.currentClassification,
			name: name,
			description: description,
		} as ClassificationForm;

		actions.updateClassification(form)
			.then(() => {
				history.push(routes.unitGroupAdmin.classifications);
			});
	}

    function onNameChange(value: string){
		setName(value);

    }

    function onDescriptionChange(value: string){
		var valueToSave = value === "" ? null : value;
		setDescription(valueToSave);
    }

	function disableSave() {
		if (params.classificationId !== undefined) {
			return (name === undefined || name == null || name === "") || (name === pageState.currentClassification.name && (description === pageState.currentClassification.description));
		} else {
			return (name === undefined || name === "" || name == null);
		}
	}

	function getTitle() {
		if (params.classificationId !== undefined) {
			return "Edit Unit Group Classification";
		} else {
			return "Create Unit Group Classification";
		}
	}

	function getNavMenu() {
		if (params.classificationId !== undefined) {
			return <EditClassificationMenu  actionArea={navbarActionArea} />;
		} else {
			return <CreateClassificationMenu  actionArea={navbarActionArea} />
		}
	}


    const navbarActionArea = (
		<RequirePermission permissions={[constants.permissions.canViewAllAdminUnitGroups]}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<Spacer orientation="h" size="xl" />
				<VisibilityToggle isVisible={!pageState.isLoading}>
				<PrimaryButton
						className="cancel-button"
						onClick={onCancel}
						title="CANCEL"
					/>
				<PrimaryButton
						className="save-button"
						disabled={disableSave() || isNullOrUndefinedOrEmptyOrWhiteSpace(name!)}
						onClick={onSave}
						title="SAVE"
					/>
					<Dialog
						header="SAVE ERROR"
						footer={renderFooter()}
						isVisible={saveError}
						onHide={() => setSaveError(false)}
					>
						Save was unsuccessful. Please try again.{" "}
					</Dialog>
				</VisibilityToggle>
			</div>
		</RequirePermission>
	);

    const createForm = () => {
        return (<Card>
            <div className="create-classification-form">
                <h1>{getTitle()}</h1>
				{ classificationLoading || pageState.isLoading ? 
					<Spinner isVisible={true}/> : 
					<div className="inputs">
						<label className="label"> Name<span className="star">*</span></label>
						<Input value={name} onChange={onNameChange}></Input>

						<Label label={"Description"}></Label>
						<Input value={description} onChange={onDescriptionChange}></Input>
					</div>
			}
            </div>
        </Card>);
    };


    return (
		<Page
			menu={getNavMenu()}
			className="create-classification-page"
		>
			<div id="content-container" className="create-classification-wrapper">
				<div className="indexBody">
					{createForm()}
				</div>
			</div>
		</Page>
	);
};

export default CreateClassificationAdminPage;