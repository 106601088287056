import React from "react";
import PageSection from "components/controls/PageSection";
import { RightContent } from "./RightContent";

import { ChangePriceHold } from "./ChangePriceHold";
import { ConcessionsGrid } from "../Concessions";
import useStyle from "./useStyle";
import constants from "utils/constants";

export const RecommendationSection = () => {
	const classes = useStyle();
	return (
		<div className={classes.container}>
			<PageSection title={constants.titleSection.recommendation} rightContent={<RightContent />}>
				<ChangePriceHold />
			</PageSection>
			<ConcessionsGrid />
		</div>
	);
};
