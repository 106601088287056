import React, { useRef, useState } from "react";
import { DataTable } from "components/controls/DataTable";
import constants from "utils/constants";
import PageSection from "components/controls/PageSection";
import { columns } from "./columns";
import { useSearchState } from "./useSearchState";
import { PriceResetPopUp } from "../PriceReset/PopUp";
import { usePopup } from "../PriceReset/usePopup";
import { useLoadData } from "./useLoadData";
import { ApprovalPopup } from "./approvePopUp";
import PrimaryButton from "components/controls/PrimaryButton";
import { SecondaryReviewListForm } from "api";
import Spacer from "components/controls/Spacer";

export const ToReview = () => {
	const { open, handleClose, handleOpen } = usePopup();
	const { open: o, handleClose: hClose, handleOpen: hOpen } = usePopup();
	const { onPage, onSort, searchState } = useSearchState();
	const showingRef = useRef<HTMLDivElement>(null);
	const hideColumnRef = useRef<HTMLDivElement>(null);
	const { localState, isLoading } = useLoadData(searchState);
	const [pageState, setPageState] = useState({ selectedRows: [] as SecondaryReviewListForm[], comment: "" });

	const resetSelectedRows = () => {
		setPageState({ ...pageState, selectedRows: [] });
	};

	return (
		<PageSection
			title={constants.titleSection.toReview}
			leftContent={<div ref={showingRef} />}
			rightContent={
				<>
					<Spacer></Spacer>
					<PrimaryButton
						onClick={() => {
							hOpen({ e: "", completed: false });
						}}
						title={`Accept Selected (${pageState.selectedRows.length})`}
						disabled={pageState.selectedRows.length === 0}
					/>
					<div ref={hideColumnRef} />
				</>
			}
		>
			<DataTable
				dataKey="group"
				onRowDoubleClick={(e) => handleOpen({ e, completed: false })}
				searchState={searchState}
				onPage={onPage}
				onSort={onSort}
				totalRecords={localState?.totalCount}
				columns={columns}
				showingRef={showingRef}
				hideColumnsRef={hideColumnRef}
				loading={isLoading}
				data={localState?.items}
				tableName={constants.tableContent.toReviewSRTitle}
				emptyMessage={constants.tableContent.toReviewSREmpty}
				selectionMode="multiple"
				selection={pageState.selectedRows}
				onSelectionChange={(e) => {
					setPageState({ ...pageState, selectedRows: e.value });
				}}
			/>
			<ApprovalPopup
				ids={[...pageState.selectedRows.map((x) => x.automatedPricingActionId)] as string[]}
				open={o}
				onClose={hClose}
				onApproval={resetSelectedRows}
			/>
			<PriceResetPopUp open={open} onClose={handleClose} columns={columns} />
		</PageSection>
	);
};
