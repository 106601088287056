import React from "react";
import { FormProvider } from "react-hook-form";
import { RecommendationSection } from "./Recommendation";
import { NotesSection } from "./Notes";
import useStyle from "./useStyle";
import { Confirmation } from "./Alerts/Confirmation";
import { usePriceForm } from "./usePriceForm";
import { PricingButton } from "components/controls/PricingButton";

interface Props {
	handleClose: () => void;
}

export const PriceResetForm = ({ handleClose }: Props) => {
	const classes = useStyle();
	const { handleValidateForm, methods, onSubmit, confirmationPopup } = usePriceForm({ handleClose });

	return (
		<FormProvider {...methods}>
			<form id="priceResetForm" onSubmit={methods.handleSubmit(onSubmit)}>
				<RecommendationSection />
				<NotesSection />
				<div className={classes.formButtons}>
					<PricingButton variant="primary" type="button" onClick={handleValidateForm}>
						Save
					</PricingButton>
					<PricingButton variant="secondary" type="button" onClick={handleClose}>
						Cancel
					</PricingButton>
				</div>
			</form>
			<Confirmation onClose={confirmationPopup.handleClose} open={confirmationPopup.open} />
		</FormProvider>
	);
};
