import React, { useState, useEffect } from "react";
import Page from "components/layouts/Page";
import UnitGroupsAdminMenu from "../UnitGroupsAdminMenu";
import RequirePermission from "../../RequirePermission";
import Spacer from "components/controls/Spacer";
import VisibilityToggle from "components/controls/VisibilityToggle";
import usePricingPlatformStore from "components/store/usePricingPlatformStore";
import constants from "utils/constants";
import useMessageArea from "components/useMessageArea";
import useFirstLoadEffect from "utils/useMountEffect";
import { MessageSeverity, MessageSeverityLevel } from "utils/messageUtils";
import { UnitGroupAdminPageProps } from "components/store/UnitGroupAdminPage/unitGroupAdminPageInterfaces";
import "./_unassigned-unit-types.scss";

import Dropdown, { DropdownOption } from "components/controls/Dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Spinner from "components/controls/Spinner";
import { Card } from "primereact/card";
import { UnassignedUnitTypeForm, UnassignedUnitTypeUnitGroupOption, UnitGroupAdminDropdownSelections} from "api";
import PrimaryButton from "components/controls/PrimaryButton";
import Dialog from "components/controls/Dialog";
import { Button } from "primereact/button";
import { UnassignedUnitTypeMapping } from "components/store/PricingPlatformStateTypes";

const UnassignedUnitTypes: React.FC<UnitGroupAdminPageProps> = function (props) {

	const [appState, appActions] = usePricingPlatformStore();
	const pageState = { ...appState.unitGroupAdminPageState };
	const actions = { ...appActions.unitGroupAdminPage };
	const [, , , setMessageBasedOnCustomMessage] = useMessageArea();	

	interface defaultUnitGroupDropdowns {
		currentFilters: UnitGroupAdminDropdownSelections;
	  }

	const [defaultUGDropdowns] = useState<defaultUnitGroupDropdowns>({
		currentFilters: {},
	});

	const [localState, setLocalState] = useState({
		unitTypes: appState.unitGroupAdminPageState.unassignedUnitTypes.unitTypes,
		totalCount: appState.unitGroupAdminPageState.unassignedUnitTypes.totalCount,
		filterOptions: appState.unitGroupAdminPageState.unassignedUnitTypeFilterOptions,
		currentFilters: defaultUGDropdowns.currentFilters,
		showCreateDialog: false,
	});

	//Track edited unittype rows
	const [pageIsDirty, setPageIsDirty] = useState(false);
	
	const [saveError, setSaveError] = useState(false);
	const renderFooter = () => {
		return (
			<div>
				<Button label="OK" icon="pi pi-check" onClick={() => setSaveError(false)} />
			</div>
		);
	};

	const rowsPerPageOptions: number[] = [10, 20, 50];

	interface SearchState {
		skip: number;
		take: number;
		rows: number;
		page: number;
		totalRecords: number;
	}

	const [searchState, setSearchState] = useState<SearchState>({
		skip: 0,
		take: 10,
		rows: rowsPerPageOptions[0],
		page: 0,
		totalRecords: 0,
	});


	const navbarActionArea = (
		<RequirePermission permissions={[constants.permissions.canViewAllUnassignedUnitTypes]}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<Spacer orientation="h" size="xl" />
				<VisibilityToggle isVisible={!pageState.isLoading}>
				<PrimaryButton
						className="navbarButton cancel-button"
						disabled={!pageIsDirty}
						onClick={onCancel}
						title="CANCEL"
					/>
				<PrimaryButton
						className="navbarButton"
						disabled={!pageIsDirty}
						onClick={onSave}
						title="SAVE"
					/>
					<Dialog
						header="SAVE ERROR"
						footer={renderFooter()}
						isVisible={saveError}
						onHide={() => setSaveError(false)}
					>
						Save was unsuccessful. Please try again.{" "}
					</Dialog>
				</VisibilityToggle>
			</div>
		</RequirePermission>
	);

	useFirstLoadEffect(() => {
		try {
			actions.updateUnassignedFilterOptions(pageState.currentUnassignedFilterSelected);
			actions.loadUnassignedUnitTypes(pageState.currentUnassignedFilterSelected, searchState.skip, searchState.take);
		} catch (error) {
			setMessageBasedOnCustomMessage(
				constants.MessageAreaMessages.ApiDownTitle,
				constants.MessageAreaMessages.ApiDownMessage,
				MessageSeverityLevel.Warning as MessageSeverity,
				false
			);
		}
	});

	useEffect(()=>{
		localState.unitTypes = pageState.unassignedUnitTypes.unitTypes;
		localState.totalCount = pageState.unassignedUnitTypes.totalCount;
	}, [localState.unitTypes,localState.totalCount, pageState.unassignedUnitTypes]);

	function onSelectDivision(division: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedDivision = division;
		curSelections.selectedProperty = undefined;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});

		setSearchState({
			...searchState,
			skip: 0,
			page: 0
		});


		actions.updateUnassignedFilterOptions(pageState.currentUnassignedFilterSelected);
		actions.loadUnassignedUnitTypes(curSelections, 0, searchState.take);
		


	}

	function onSelectProperty(property: DropdownOption | undefined) {
		var curSelections = { ...localState.currentFilters };
		curSelections.selectedProperty = property;
		setLocalState({
			...localState,
			currentFilters: curSelections
		});

		setSearchState({
			...searchState,
			skip: 0,
			page: 0
		});


		actions.updateUnassignedFilterOptions(pageState.currentUnassignedFilterSelected);
		actions.loadUnassignedUnitTypes(curSelections, 0, searchState.take);



		
	}

	function onCancel(){


		var currentMapping = pageState.unassignedUnitTypeMapping;

		var updatedMapping = currentMapping.map((x: UnassignedUnitTypeMapping)=>{

			if(x.selectedUnitGroup !== undefined){
				x.selectedUnitGroup = undefined;
			}

			return x;
		});
		
		actions.updateUnitTypeUnitGroupMapping(updatedMapping);
		setPageIsDirty(false);
	}

	function onSave(){

		var curSelections = { ...localState.currentFilters };
		//Get updated types
		var currentMapping = pageState.unassignedUnitTypeMapping;
		var updatedMapping = currentMapping.filter((x: UnassignedUnitTypeMapping)=>{return x.selectedUnitGroup !== undefined});
		
		//Call api endpoint for saving updated unittype
		actions.saveUnitTypeUnitGroupMapping(
			updatedMapping,
			curSelections, 
			searchState.skip, 
			searchState.take
		);
		setPageIsDirty(false);

	}

	function onPage(e: any) {
		var curSelections = { ...localState.currentFilters };
		actions.loadUnassignedUnitTypes(curSelections, e.first, e.rows);

		setSearchState({
			...searchState,
			skip: e.first,
			take: e.rows,
			page: e.page
		});
	}

	function unitGroupTemplate(option: DropdownOption){

		var stringSections = option.displayValue!.split("}");
		return (
			<span className={'unitgroup-dropdown-item'}>{stringSections[0]} <span className="unitgroup-dropdown-description">{stringSections.length >1 ? `(${stringSections[1]})`:''}</span></span>
		);
	}

	function unitGroupValueTemplate(option: DropdownOption | undefined, object: UnassignedUnitTypeForm){

		if(option){
			var stringSections = option.displayValue!.split("}");
			return (
				<span className={'unitgroup-selection-wrapper'} >
				<span className={'unitgroup-dropdown-item'}>{stringSections[0]}</span> <span className="unitgroup-dropdown-description">{stringSections.length >1 ? `(${stringSections[1]})`:''}</span>
				</span>
			);
		}

		return (
			<span>
				Select a unit group
			</span>
		);

	}

	function assignmentTemplate(unitType: UnassignedUnitTypeForm){
		return (
		<Dropdown
			placeholder={"Select unit group"}
			selectedValue={
				pageState.unassignedUnitTypeMapping.filter(x => x.unitTypeId === unitType.unitTypeId).length > 0
				? pageState.unassignedUnitTypeMapping.filter(x => x.unitTypeId === unitType.unitTypeId)[0].selectedUnitGroup
				: undefined }
			options={
				unitType.unitGroups!.map((x: UnassignedUnitTypeUnitGroupOption)=>{
					return {key: x.id, displayValue: `${x.name} ${x.description?'}'+x.description:''}` }
				})
			}
			onChange={(selectedUnitGroup: DropdownOption | undefined) => {
				var currentMapping = pageState.unassignedUnitTypeMapping;
				var index = currentMapping.map(x => x.unitTypeId).indexOf(unitType.unitTypeId);

				if (index >= 0) {
					currentMapping[index].selectedUnitGroup = selectedUnitGroup;
					actions.updateUnitTypeUnitGroupMapping(currentMapping);
				}

				if (currentMapping.filter(x => x.selectedUnitGroup !== undefined).length > 0) {
					setPageIsDirty(true);
				} else {
					setPageIsDirty(false);
				}
			}}
			valueTemplate={unitGroupValueTemplate}
			itemTemplate={unitGroupTemplate}
			style={{ width: 350 }}
			></Dropdown>);
		}


	const dataTable = () => {        
        if(pageState.unassignedUnitTypes === undefined){
			return <Spinner isVisible={true} />;
        }else {
			return (
				<Card>
				<DataTable
					id="unassigned-unittype-list"
					value={localState.unitTypes}
					paginator={true}
					alwaysShowPaginator={true}
					pageLinkSize={5}
					className="unittype-list p-datatable-striped"
					tableClassName="unittype-list-table"
					lazy={true}
					onPage={onPage}
					loading={pageState.tableLoading}
					totalRecords={localState.totalCount}
					first={searchState.skip}
					rows={searchState.take}
					rowsPerPageOptions={rowsPerPageOptions}
					dataKey="id"
					emptyMessage={`${
						pageState.isLoading
							? "No unassigned unit types found"
							: "No unassigned unit types found. You either need to adjust the page filters, or you do not have permission to view any unassigned unit types."
					}`}
					paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords} unit types"
				>
					<Column
						field="propertyName"
						body={(x: UnassignedUnitTypeForm)=>{return <span>{x.propertyName}</span>}}
						header="Property"
						bodyStyle={{ width: "60px" }}
						headerStyle={{ width: "60px" }}
					></Column>
					<Column
						field="unitTypeId"
						header="Unit Type"
						bodyStyle={{ width: "60px" }}
						headerStyle={{ width: "60px" }}
					></Column>
					<Column
						field="unitTypeName"
						header="Description"
						bodyStyle={{ marginLeft: "10px", marginRight: "10px", width: "125px", textAlign: "let" }}
						headerStyle={{ marginLeft: "10px", marginRight: "10px", width: "125px", textAlign: "left" }}
					></Column>
					<Column
						header="Unit Group Assignment"
						bodyStyle={{ marginLeft: "10px", marginRight: "10px", width: "145px", textAlign: "left" }}
						headerStyle={{ marginLeft: "10px", marginRight: "10px", width: "145px", textAlign: "left" }}
						body={assignmentTemplate}
					></Column>
				</DataTable>
				</Card>
			);
		}
	};

	return (
		<Page menu={<UnitGroupsAdminMenu actionArea={navbarActionArea} />} className="unassigned-unit-types">
			<div id="unitGroupAdminFilters" className="filter-headers-1">
				<div className="filter-item">
				<span className="filter-title">Division</span>
					<div className="filter-controls">
						<Dropdown
							placeholder={"Select division"}
							selectedValue={localState.currentFilters.selectedDivision}
							options={pageState.unassignedUnitTypeFilterOptions!.divisions}
							onChange={(d) => onSelectDivision(d)}
							disabled={pageState.unassignedUnitTypeFilterOptions!.divisions === undefined}
							filter
							style={{ width: 350 }}
						></Dropdown>
					</div>
				</div>
                <div className="filter-item">
                <span className="filter-title">Property</span>
                <div className="filter-controls">
						<Dropdown
							placeholder={"Select property"}
							selectedValue={localState.currentFilters.selectedProperty}
							options={pageState.unassignedUnitTypeFilterOptions!.properties}
							onChange={(d) => onSelectProperty(d)}
							disabled={pageState.unassignedUnitTypeFilterOptions!.properties === undefined}
							filter
							style={{ width: 350 }}
						></Dropdown>
					</div>
                </div>
			</div>
			<div id="contentContainer" className="">
				{pageState.isLoading ? <ProgressSpinner /> : <div className="unassignedBody">{dataTable()}</div>}
			</div>
		</Page>
	);
};

export default UnassignedUnitTypes;
