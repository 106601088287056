import React, { useState } from "react";
import pageStyle from "./UnitGroupList.module.css";
import { PropertySummary, UnitGroupSummary } from "api";
import UnitGroupCard from "./UnitGroupCard";
import { UpdatedUnitGroupDelta } from "components/store/PropertiesPage/propertyPageInterfaces";
import { InputSwitch } from "primereact/inputswitch";
export interface UnitGroupListInputProps {
	property: PropertySummary;
	savedUnitGroups: UnitGroupSummary[];
	setSavedUnitGroups: React.Dispatch<React.SetStateAction<UnitGroupSummary[]>>;
	updateUnitGroupState: (unitGroup: UpdatedUnitGroupDelta) => void;
}

export default function UnitGroupList(props: UnitGroupListInputProps) {
	const { property } = props;
	const propertyId = property && property.id;

	interface UnitGroupListState {
		isLoaded?: boolean;
		calledApi?: boolean;
		errorMessage?: string;
		unitGroups?: UnitGroupSummary[];
	}

	const [isHoldAllActive, setIsHoldAllActive] = useState<boolean>(false);

	const [state, setState] = useState<UnitGroupListState>({
		unitGroups: property.unitGroups
	});

	function holdAllToggleOnChange(isHoldAllActive: boolean) {
		if (state.unitGroups) {
			//create a copy of the state with slice
			let foundUGs = state.unitGroups.slice();
			foundUGs.forEach((ug) => {
				if (ug.isHoldActive !== isHoldAllActive) {
					//only update if it really changed
					ug.isHoldActive = isHoldAllActive;
				}
			});
			//update the state. child UG card will react and show hold
			setState({ ...state, unitGroups: foundUGs });
		}

		setIsHoldAllActive(isHoldAllActive);
	}

	function watchUnitGroupState(unitGroupDelta: UpdatedUnitGroupDelta) {
		if (!state.unitGroups || state.unitGroups.length === 0) {
			setIsHoldAllActive(false);
			return;
		}

		state.unitGroups.forEach((ug) => {
			//update our records from the child component
			if (ug.id === unitGroupDelta.unitGroupId) {
				ug.isHoldActive = unitGroupDelta.hold;
			}
		});

		const areAllHeld = state.unitGroups.every((ug) => ug.isHoldActive === true);
		setIsHoldAllActive(areAllHeld);

		//bubble up to parent
		props.updateUnitGroupState(unitGroupDelta);
	}

	if (!property || !property.id) {
		return <div>No property supplied</div>;
	}

	return (
		<div key={"unit-group-list_" + property} className={pageStyle.unitGroupList}>
			<div className={pageStyle.unitGroupListHeader}>
				<div className={pageStyle.unitGroupListHeaderTitleArea}>
					<h3>Matching Unit Groups for {property.name} </h3>
				</div>
				<div className={pageStyle.unitGroupListHeaderActionsArea}>
					<label id="isHoldActiveLabel" htmlFor="holdSwitch">
						Hold All:
					</label>
					<InputSwitch
						id="holdSwitch"
						checked={isHoldAllActive}
						onChange={(e) => {
							holdAllToggleOnChange(e.value);
						}}
						ariaLabelledBy="isHoldActiveLabel"
					/>
				</div>
			</div>

			{state.errorMessage && <div>{state.errorMessage}</div>}

			{property.unitGroups &&
				property.unitGroups.map((unitGroup: UnitGroupSummary) => {
					return (
						<UnitGroupCard
							propertyId={propertyId}
							unitGroup={unitGroup}
							key={unitGroup.id}
							savedUnitGroups={props.savedUnitGroups}
							setSavedUnitGroups={props.setSavedUnitGroups}
							updateUnitGroupState={watchUnitGroupState}
							isHoldAllActive={isHoldAllActive}
							setIsHoldAllActive={setIsHoldAllActive}
						/>
					);
				})}
		</div>
	);
}
