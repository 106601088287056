import {
	UnitGroupAdminClient,
	UnitType,
	UnitGroupAdminForm,
	UnitGroupAdminResultForm,
	UnitGroupAdminDropdownSelections,
	UnitGroupAdminFilter,	
	UnitFilterOptions,
	UnitGroupMaster,
	ClassificationForm,
	UnassignedUnitTypePageForm,
	Bedroom,
	BedroomForm,
	UnitGroupMasterForm,
	UnitGroupMasterFilter,
	UnitGroupMasterPageForm,
	UnitGroupAdminDetailForm,
	UpdateUnitGroupMasterForm,
	UnitGroupAdminDeleteResultForm,
	UnitGroupAdminSummaryPageForm,
	UnitTypeForm
} from "api";
import { PricingPlatformActions } from "../PricingPlatformActions";
import { PricingPlatformActionTypes } from "../PricingPlatformActionTypes";
import { Identity } from "components/IdentityProvider";
import { getApiEndpoint } from "components/useApiEndpoint";
import { makeRemoteRequest } from "components/useRemoteData";
import { UnitGroupAdminPageParams } from "./unitGroupAdminPageInterfaces";
import { UnassignedUnitTypeMapping, UnitTypeUnitGroupMapping } from "../PricingPlatformStateTypes";
import { DropdownOption } from "components/controls/Dropdown";

export const UnitGroupAdminPageActionMethods = {
	get: function (dispatcher: React.Dispatch<PricingPlatformActions>, identity: Identity) {
		const getRawSelectionsFromParams = function (
			params: UnitGroupAdminPageParams,
			appliedOptions: UnitGroupAdminDropdownSelections
		): UnitGroupAdminDropdownSelections {
			var selections: UnitGroupAdminDropdownSelections = appliedOptions;

			if (params.divisionId !== ":divisionId" && params.divisionName !== ":divisionName") {
				selections.selectedDivision = { key: params.divisionId, displayValue: params.divisionName };
			}

			if (params.propertyId !== ":propertyId" && params.propertyName !== ":propertyName") {
				selections.selectedProperty = { key: params.propertyId, displayValue: params.propertyName };
			}

			return selections;
		};

		const matchOptionsWithParams = function (
			options: UnitFilterOptions,
			params: UnitGroupAdminPageParams
		): UnitGroupAdminDropdownSelections {

			var selectedDivision:DropdownOption | undefined = undefined;
			var selectedProperty:DropdownOption |undefined = undefined;

			if (options.divisions !== null && options.divisions !== undefined) {
				selectedDivision = params.divisionId !== ":divisionId"? options.divisions.find((x: DropdownOption) => x.key === params.divisionId): undefined;
			}

			if (options.properties !== null && options.properties !== undefined) {
				selectedProperty = params.propertyId !== ":propertyId"? options.properties.find((x:DropdownOption) => x.key === params.propertyId): undefined;
			}

			var dropdownSelections: UnitGroupAdminDropdownSelections = {
				selectedDivision: selectedDivision,
				selectedProperty: selectedProperty
			};
			return dropdownSelections;
		};

		const getUnitGroupMasters = (): Promise<UnitGroupMaster[]> => {
			dispatcher({
				type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingUnitGroupMasters,
				payload: true
			});

			let unitGroupAdminEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getUnitGroupMasters");

			try {
				return makeRemoteRequest(unitGroupAdminEndpoint, []).then((response) => {
					if (response === undefined) {
						throw new Error();
					}

					var data = response.data as UnitGroupMaster[];

					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageLoadedUnitGroupMasters,
						result: data
					});

					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingUnitGroupMasters,
						payload: false
					});
					return data;
				});
			} catch (err) {
				throw err;
			}
		};

		const getUnitGroupMastersWithBedrooms = (): Promise<UnitGroupMaster[]> => {
			dispatcher({
				type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingUnitGroupMasters,
				payload: true
			});

			let unitGroupAdminEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getUnitGroupMastersWithBedrooms");

			return new Promise((resolve, reject) => {
				return makeRemoteRequest(unitGroupAdminEndpoint, [])
					.then((response) => {
						if (response === undefined) {
							throw new Error();
						}

						var data = response.data as UnitGroupMaster[];

						dispatcher({
							type: PricingPlatformActionTypes.UnitGroupAdminLoadedUnitGroupMastersWithBedrooms,
							result: data
						});

						dispatcher({
							type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingUnitGroupMasters,
							payload: false
						});
						return data;
					})
					.catch((err) => {
						reject(err);
					});
			});
		};

		const getUnitGroupMasterPage = (skip: number, take: number): void => {
			dispatcher({
				type: PricingPlatformActionTypes.UnitGroupAdminPageLoadTableAction,
				payload: true
			});

			let unitGroupAdminEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getUnitGroupMasterPage");

			var filter: UnitGroupMasterFilter = {
				skip: skip,
				take: take
			};

			makeRemoteRequest(unitGroupAdminEndpoint, [filter])
				.then((response) => {
					if (response === undefined) {
						throw new Error();
					}

					var data = response.data as UnitGroupMasterPageForm;

					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminListUnitGroupMasterPage,
						unitGroupMasterPage: data
					});

					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageLoadTableAction,
						payload: false
					});

					return data;
				})
				.catch((err) => {});
		};

		const createUnitGroupMaster = async (form: UnitGroupMasterForm): Promise<string> => {
			let apiEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "createUnitGroupMaster");

			return new Promise((resolve, reject) => {
				makeRemoteRequest(apiEndpoint, [form])
					.then((response) => {
						if (response === undefined) {
							throw new Error();
						}

						resolve(response.data!);
					})
					.catch((err) => {
						reject(err);
					});
			});
		};

		const getUpdateUnitGroupMasterForm = (
			unitGroupMasterId: string
		): Promise<UpdateUnitGroupMasterForm | undefined> => {
			let unitGroupAdminEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getUnitGroupMasterFormById");
			dispatcher({
				type: PricingPlatformActionTypes.UpdateLoadingState,
				loadingState: { isLoading: true }
			});

			return new Promise((resolve, reject) => {
				makeRemoteRequest(unitGroupAdminEndpoint, [unitGroupMasterId])
					.then((response) => {
						if (response === undefined) {
							throw new Error();
						}

						var data = response.data as UpdateUnitGroupMasterForm;

						dispatcher({
							type: PricingPlatformActionTypes.UnitGroupAdminListBedroomsDropdownAction,
							bedroomDropdowns: data.bedrooms!
						});

						dispatcher({
							type: PricingPlatformActionTypes.UpdateLoadingState,
							loadingState: { isLoading: false }
						});

						resolve(data);
					})
					.catch((err) => {
						reject(err);
					});
			});
		};

		const updateUnitGroupMaster = async (unitGroups: UnitGroupMasterForm): Promise<string> => {
			let apiEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "updateUnitGroupMaster");

			return new Promise((resolve, reject) => {
				makeRemoteRequest(apiEndpoint, [unitGroups])
					.then((response) => {
						if (response === undefined) {
							throw new Error();
						}

						resolve(response!.data!);
					})
					.catch((err) => {
						reject(err);
					});
			});
		};

		const getClassifications = (): Promise<ClassificationForm[]> => {
			dispatcher({
				type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingClassifications,
				payload: true
			});

			dispatcher({
				type: PricingPlatformActionTypes.UpdateUnitGroupAdminPageIsLoading,
				payload: true
			});

			let unitGroupAdminEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getClassifications");

			try {
				return makeRemoteRequest(unitGroupAdminEndpoint, []).then((response) => {
					if (response === undefined) {
						throw new Error();
					}
					var data = response.data as ClassificationForm[];
					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageLoadedClassifications,
						result: data
					});
					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingClassifications,
						payload: false
					});

					dispatcher({
						type: PricingPlatformActionTypes.UpdateUnitGroupAdminPageIsLoading,
						payload: false
					});
					return data;
				});
			} catch (err) {
				throw err;
			}
		};

		const createClassification = function (form: ClassificationForm): Promise<string | null | undefined> {
			let apiEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "createClassification");

			return new Promise((resolve, reject) => {
				makeRemoteRequest(apiEndpoint, [form])
					.then((response) => {
						if (response === undefined) {
							throw new Error();
						}

						resolve(response!.data);
					})
					.catch((err) => {
						reject(err);
					});
			});
		};

		const loadUnitTypes = async (propertyId: string): Promise<void | UnitType[] | undefined> => {
			dispatcher({
				type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingUnitTypes,
				payload: true
			});

			let unitGroupAdminEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getUnitTypesByPropertyId");

			try {
				const response = await makeRemoteRequest(unitGroupAdminEndpoint, [propertyId]);
				if (response === undefined) {
					throw new Error();
				}

				var data = response.data as UnitType[];

				dispatcher({
					type: PricingPlatformActionTypes.UnitGroupAdminPageLoadedUnitTypes,
					result: data
				});

				dispatcher({
					type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingUnitTypes,
					payload: false
				});
				return data;
			} catch (err) {
				throw err;
			}
		};

		const getUnitGroupDetailByPropertyId = (
			propertyId: string
		): Promise<UnitGroupAdminDetailForm | boolean | undefined> => {
			dispatcher({
				type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingUnitGroupDetails,
				payload: true
			});

			dispatcher({
				type: PricingPlatformActionTypes.UpdateUnitGroupAdminPageIsLoading,
				payload: true
			});

			let unitGroupAdminEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getUnitGroupDetailByPropertyId");

			try {
				return makeRemoteRequest(unitGroupAdminEndpoint, [propertyId]).then((response) => {
					if (response === undefined) {
						throw new Error();
					}

					var data = response.data as UnitGroupAdminDetailForm;

					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageLoadedUnitGroupDetails,
						result: data
					});

					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingUnitGroupDetails,
						payload: false
					});

					dispatcher({
						type: PricingPlatformActionTypes.UpdateUnitGroupAdminPageIsLoading,
						payload: false
					});

					return data;
				});
			} catch (err) {
				throw err;
			}
		};

		const getUnitGroupDetailByIds = (
			propertyId: string,
			unitGroupId: string | null = null
		): Promise<UnitGroupAdminDetailForm | boolean | undefined> => {
			dispatcher({
				type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingUnitGroupDetails,
				payload: true
			});

			dispatcher({
				type: PricingPlatformActionTypes.UpdateUnitGroupAdminPageIsLoading,
				payload: true
			});

			let unitGroupAdminEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getUnitGroupDetailByIds");

			try {
				return makeRemoteRequest(unitGroupAdminEndpoint, [propertyId, unitGroupId]).then((response) => {
					if (response === undefined) {
						throw new Error();
					}

					var data = response.data as UnitGroupAdminDetailForm;

					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageLoadedUnitGroupDetails,
						result: data
					});

					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingUnitGroupDetails,
						payload: false
					});

					dispatcher({
						type: PricingPlatformActionTypes.UpdateUnitGroupAdminPageIsLoading,
						payload: false
					});
					return data;
				});
			} catch (err) {
				throw err;
			}
		};

		const getUnitTypesByPropertyAndGroupIds = (propertyId: string, unitGroupId: string): Promise<UnitTypeForm[] | boolean | undefined> => {
			dispatcher({
				type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingUnitTypes,
				payload: true
			});

			dispatcher({
				type: PricingPlatformActionTypes.UpdateUnitGroupAdminPageIsLoading,
				payload: true
			});


			let unitGroupAdminEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getUnitTypesByPropertyAndUnitGroupIds");
									
			try {
				return makeRemoteRequest(unitGroupAdminEndpoint, [propertyId, unitGroupId]).then((response) => {
					if (response === undefined) {
						throw new Error();
					}
					
					var data = response.data as UnitTypeForm[];
	
					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageLoadedUnitTypes,
						result: data
					});
	
					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingUnitTypes,
						payload: false
					});

					dispatcher({
						type: PricingPlatformActionTypes.UpdateUnitGroupAdminPageIsLoading,
						payload: false
					});
		
					return data;	
				});
			} catch (err) {throw(err);}			
		};

		const createUnitGroup = async (unitGroups: UnitGroupAdminForm): Promise<UnitGroupAdminResultForm> => {
			let apiEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "createUnitGroup");

			try {
				const response = await makeRemoteRequest(apiEndpoint, [unitGroups]);
				const data = response.data as UnitGroupAdminResultForm;
				dispatcher({
					type: PricingPlatformActionTypes.UnitGroupAdminPageCreateUnitGroups,
					savedUnitGroup: data
				});
				return data;
			} catch (err) {
				throw err;
			}
		};

		const updateUnitGroup = async (unitGroups: UnitGroupAdminForm): Promise<UnitGroupAdminResultForm> => {
			let apiEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "updateUnitGroup");

			try {
				const response = await makeRemoteRequest(apiEndpoint, [unitGroups]);
				const data = response.data as UnitGroupAdminResultForm;
				dispatcher({
					type: PricingPlatformActionTypes.UnitGroupAdminPageUpdateUnitGroups,
					updateUnitGroup: data
				});
				return data;
			} catch (err) {
				throw err;
			}
		};

		const deleteUnitGroup = async (
			propertyId: string,
			unitGroupId: string
		): Promise<UnitGroupAdminDeleteResultForm> => {
			let apiEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "deleteUnitGroups");
			try {
				const response = await makeRemoteRequest(apiEndpoint, [propertyId, unitGroupId]);
				const data = response.data as UnitGroupAdminDeleteResultForm;
				console.log(data);
				dispatcher({
					type: PricingPlatformActionTypes.UnitGroupAdminPageDeleteUnitGroups,
					deleteUnitGroup: data
				});
				return data;
			} catch (err) {
				throw err;
			}
		};

		const updateUnitGroupMappingFilterOptionsAndSearch = function (
			currentSelections: UnitGroupAdminDropdownSelections,
			skip: number,
			take: number,
			params?: UnitGroupAdminPageParams
		): void {
			let filterOptionsEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getFilterOptions");

			makeRemoteRequest(filterOptionsEndpoint, [currentSelections]).then((response) => {
				var options = response.data as UnitFilterOptions;

				var selectedOptionFromParam:UnitGroupAdminDropdownSelections = {
					selectedDivision: undefined,
					selectedProperty: undefined
				};
				var rawSelectionsFromParam:UnitGroupAdminDropdownSelections= {
					selectedDivision: undefined,
					selectedProperty: undefined
				};

				if(params){
					selectedOptionFromParam = matchOptionsWithParams(options, params);
					rawSelectionsFromParam = getRawSelectionsFromParams(params, currentSelections);
				}
				

				dispatcher({
					type: PricingPlatformActionTypes.UnitGroupAdminPageUpdateUnitGroupFilterOptionsAction,
					updatedOptions: options
				});

				if (selectedOptionFromParam.selectedProperty !== undefined || selectedOptionFromParam.selectedDivision !== undefined) {
					searchProperty(rawSelectionsFromParam, skip, take);
				}
			});
		};

		const updateUnitGroupMappingFilterOptions = function (
			currentSelections: UnitGroupAdminDropdownSelections
		): void {
			let filterOptionsEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getFilterOptions");

			makeRemoteRequest(filterOptionsEndpoint, [currentSelections]).then((response) => {
				var options = response.data as UnitFilterOptions;

				dispatcher({
					type: PricingPlatformActionTypes.UnitGroupAdminPageUpdateUnitGroupFilterOptionsAction,
					updatedOptions: options
				});

			});
		};

		const clearUnitGroupMapping = function(): void{

			dispatcher({
				type: PricingPlatformActionTypes.UnitGroupAdminPageLoadUnitGroupTableAction,
				pagedResult: [] as UnitGroupAdminSummaryPageForm
			});
		};

		const updateUnassignedFilterOptions = function (currentSelections: UnitGroupAdminDropdownSelections): void {
			let filterOptionsEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getFilterOptions");

			makeRemoteRequest(filterOptionsEndpoint, [currentSelections]).then((response) => {
				var options = response.data as UnitFilterOptions;

				dispatcher({
					type: PricingPlatformActionTypes.UnitGroupAdminPageUpdateUnassignedUnitTypesFilterOptionsAction,
					updatedOptions: options
				});
			});
		};

		const searchProperty = function (
			currentFilters: UnitGroupAdminDropdownSelections,
			skip: number,
			take: number
		): Promise<void | UnitGroupAdminSummaryPageForm | null | undefined> {
			dispatcher({
				type: PricingPlatformActionTypes.UnitGroupAdminPageLoadTableAction,
				payload: true
			});

			let apiEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getUnitGroupAdminForm");

			var propId = currentFilters.selectedProperty ? currentFilters.selectedProperty.key : undefined;
			var divId = currentFilters.selectedDivision ? currentFilters.selectedDivision.key : undefined;

			var filters: UnitGroupAdminFilter = {
				propertyId: propId,
				divisionId: divId,
				skip: skip,
				take: take
			};

			return makeRemoteRequest(apiEndpoint, [filters])
				.then((response) => {
					if (response === undefined) {
						throw new Error();
					}

					var serverValues = response.data as UnitGroupAdminSummaryPageForm;

					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageLoadUnitGroupTableAction,
						pagedResult: serverValues
					});

					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageLoadTableAction,
						payload: false
					});

					return response.data;
				})
				.catch((err) => {});
		};

		const loadUnassignedUnitTypes = function (
			currentFilters: UnitGroupAdminDropdownSelections,
			skip: number,
			take: number
		): void {
			dispatcher({
				type: PricingPlatformActionTypes.UnitGroupAdminPageLoadTableAction,
				payload: true
			});

			let apiEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getUnassignedUnitTypes");

			var propId = currentFilters.selectedProperty ? currentFilters.selectedProperty!.key : undefined;
			var divId = currentFilters.selectedDivision ? currentFilters.selectedDivision!.key : undefined;

			var filters: UnitGroupAdminFilter = {
				propertyId: propId,
				divisionId: divId,
				skip: skip,
				take: take
			};

			makeRemoteRequest(apiEndpoint, [filters])
				.then((response) => {
					if (response === undefined) {
						throw new Error();
					}

					var serverValues = response.data as UnassignedUnitTypePageForm;

					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageLoadUnassignedUnitTypes,
						result: serverValues
					});

					var unitTypeUnitGroupMapping = serverValues.unitTypes!.map((x) => {
						return { unitTypeId: x.unitTypeId, selectedUnitGroup: undefined } as UnassignedUnitTypeMapping;
					});

					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageSetUnassignedMappings,
						payload: unitTypeUnitGroupMapping
					});

					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageLoadTableAction,
						payload: false
					});

					return response.data;
				})
				.catch((err) => {});
		};

		const loadBedrooms = function (): void {
			dispatcher({
				type: PricingPlatformActionTypes.UnitGroupAdminPageLoadTableAction,
				payload: true
			});

			dispatcher({
				type: PricingPlatformActionTypes.UpdateUnitGroupAdminPageIsLoading,
				payload: true
			});

			let apiEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getBedrooms");

			makeRemoteRequest(apiEndpoint, [])
				.then((response) => {
					if (response === undefined) {
						throw new Error();
					}

					var serverValues = response.data as Bedroom[];

					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminListBedroomsTableAction,
						unitGroupBedrooms: serverValues
					});

					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageLoadTableAction,
						payload: false
					});

					dispatcher({
						type: PricingPlatformActionTypes.UpdateUnitGroupAdminPageIsLoading,
						payload: false
					});
					return response.data;
				})
				.catch((err) => {});
		};

		const loadBedroom = (bedroomId: string): Promise<Bedroom> => {
			dispatcher({
				type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingBedroom,
				payload: true
			});

			let apiEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getBedroomById");

			return new Promise((resolve, reject) => {
				makeRemoteRequest(apiEndpoint, [bedroomId])
					.then((response) => {
						if (response === undefined) {
							throw new Error();
						}

						var data = response.data as Bedroom;

						dispatcher({
							type: PricingPlatformActionTypes.UnitGroupAdminPageLoadedBedroom,
							unitGroupBedroom: data
						});
						dispatcher({
							type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingBedroom,
							payload: false
						});

						resolve(data);
					})
					.catch((err) => {
						reject();
					});
			});
		};

		const loadBedroomDropdowns = function (): Promise<DropdownOption[]> {
			let apiEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getBedrooms");

			return new Promise((resolve, reject) => {
				makeRemoteRequest(apiEndpoint, [])
					.then((response) => {
						if (response === undefined) {
							throw new Error();
						}

						var data = response.data as BedroomForm[];

						var bedroomDropdowns = data!.map((x: BedroomForm) => {
							return { key: x.bedroomId, displayValue: JSON.stringify(x.bedroomCount) } as DropdownOption;
						});

						dispatcher({
							type: PricingPlatformActionTypes.UnitGroupAdminListBedroomsDropdownAction,
							bedroomDropdowns: bedroomDropdowns
						});

						return resolve(bedroomDropdowns);
					})
					.catch((err) => {
						return reject(err);
					});
			});
		};

		const createBedroom = function (form: BedroomForm): Promise<string | null | undefined> {
			let apiEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "createBedroom");

			return new Promise((resolve, reject) => {
				makeRemoteRequest(apiEndpoint, [form])
					.then((response) => {
						if (response === undefined) {
							throw new Error();
						}

						resolve(response!.data);
					})
					.catch((err) => {
						reject(err);
					});
			});
		};

		const updateBedroom = (form: BedroomForm): Promise<string | null | undefined> => {
			let apiEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "updateBedroom");

			return new Promise((resolve, reject) => {
				makeRemoteRequest(apiEndpoint, [form])
					.then((response) => {
						if (response === undefined) {
							throw new Error();
						}

						resolve(response!.data);
					})
					.catch((err) => {
						reject(err);
					});
			});
		};

		const updateUnitTypeUnitGroupMapping = function (updatedMapping: UnassignedUnitTypeMapping[]): void {
			dispatcher({
				type: PricingPlatformActionTypes.UnitGroupAdminPageSetUnassignedMappings,
				payload: updatedMapping
			});
		};

		const saveUnitTypeUnitGroupMapping = function (
			unitTypeUnitGroupMappings: UnassignedUnitTypeMapping[],
			currentFilters: UnitGroupAdminDropdownSelections,
			skip: number,
			take: number
		): void {
			var unitTypesToMap = unitTypeUnitGroupMappings.map((x: UnassignedUnitTypeMapping) => {
				return { unitGroupId: x.selectedUnitGroup!.key, unitTypeId: x.unitTypeId } as UnitTypeUnitGroupMapping;
			});

			let apiEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "saveUnitGroupIdToUnitType");

			makeRemoteRequest(apiEndpoint, [unitTypesToMap])
				.then((response) => {
					if (response === undefined) {
						throw new Error();
					}

					if (response.data!.toLowerCase() === "true") {
						loadUnassignedUnitTypes(currentFilters, skip, take);
					}

					return response.data;
				})
				.catch((err) => {});
		};

		const loadClassifications = function (): void {
			dispatcher({
				type: PricingPlatformActionTypes.UnitGroupAdminPageLoadTableAction,
				payload: true
			});

			dispatcher({
				type: PricingPlatformActionTypes.UpdateLoadingState,
				loadingState: { isLoading: true }
			});

			let apiEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getClassifications");

			makeRemoteRequest(apiEndpoint, [])
				.then((response) => {
					if (response === undefined) {
						throw new Error();
					}

					var serverValues = response.data as ClassificationForm[];

					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageLoadedClassifications,
						result: serverValues
					});

					dispatcher({
						type: PricingPlatformActionTypes.UnitGroupAdminPageLoadTableAction,
						payload: false
					});

					dispatcher({
						type: PricingPlatformActionTypes.UpdateLoadingState,
						loadingState: { isLoading: false }
					});

					return response.data;
				})
				.catch((err) => {});
		};

		const loadClassification = function (classificationId: string): Promise<void> {
			dispatcher({
				type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingClassifications,
				payload: true
			});

			let apiEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "getClassification");

			return new Promise((resolve, reject) => {
				makeRemoteRequest(apiEndpoint, [classificationId])
					.then((response) => {
						if (response === undefined) {
							throw new Error();
						}

						var data = response.data as ClassificationForm;

						dispatcher({
							type: PricingPlatformActionTypes.UnitGroupAdminPageLoadClassificationUpdate,
							payload: data
						});
						dispatcher({
							type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingClassifications,
							payload: false
						});

						resolve();
					})
					.catch((err) => {
						reject();
					});
			});
		};

		const updateClassification = function (classificationForm: ClassificationForm): Promise<boolean> {
			dispatcher({
				type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingClassifications,
				payload: true
			});

			let apiEndpoint = getApiEndpoint(identity, UnitGroupAdminClient, "updateClassification");

			return new Promise((resolve, reject) => {
				makeRemoteRequest(apiEndpoint, [classificationForm])
					.then((response) => {
						if (response === undefined) {
							throw new Error();
						}

						dispatcher({
							type: PricingPlatformActionTypes.UnitGroupAdminPageFetchingClassifications,
							payload: false
						});

						resolve(true);
					})
					.catch((err) => {
						reject(false);
					});
			});
		};

		return {
			searchProperty,
			getRawSelectionsFromParams,
			matchOptionsWithParams,
			getUnitGroupMasters,
			getUnitGroupMastersWithBedrooms,
			getUnitGroupMasterPage,
			createUnitGroupMaster,
			getUpdateUnitGroupMasterForm,
			updateUnitGroupMaster,
			loadClassifications,
			createClassification,
			getClassifications,
			loadUnitTypes,
			getUnitGroupDetailByPropertyId,
			getUnitGroupDetailByIds,
			getUnitTypesByPropertyAndGroupIds,
			loadUnassignedUnitTypes,
			loadBedrooms,
			loadBedroom,
			loadBedroomDropdowns,
			createBedroom,
			updateBedroom,
			updateUnitTypeUnitGroupMapping,
			createUnitGroup,
			updateUnitGroup,
			deleteUnitGroup,
			updateUnitGroupMappingFilterOptionsAndSearch,
			updateUnitGroupMappingFilterOptions,
			updateUnassignedFilterOptions,
			clearUnitGroupMapping,
			saveUnitTypeUnitGroupMapping,
			loadClassification,
			updateClassification
		};
	}
};
